import React from 'react';
import "./sideDrawer.css";

import { HashLink } from "react-router-hash-link";
const sideDrawer = props => (
    
    <nav className="sideDrawer">
     
        <ul>
        <HashLink to="/#"><li>Home</li></HashLink>
        <HashLink to="/Live#"><li>MB Live</li></HashLink>
        <HashLink to="/#"><li>MB Tactics</li></HashLink>
        <HashLink to="/Contact#"><li>Contact</li></HashLink>
        </ul>
  
    </nav>
    

);

export default sideDrawer;