import "../../App.css";
import React, { Component } from "react";
import Header from "../header/Header";
import SideDrawer from "../sideDrawer/sideDrawer";
import Backdrop from "../backdrop/backdrop";
import ReactPlayer from 'react-player';
import VideoHome from "../Videolist/Videohome";
import VideoAll from "../../components/Videolist/Videoall";
import Facebook from "../../images/facebook.svg";
import Twitchlink from "../../images/twitch.svg";

class VidF extends Component {

  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  };  
  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer />
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
     
    <div className="App">
      <Header drawerClickHandler={this.drawerToggleClickHandler} />
    
       {sideDrawer}
       {backdrop}
       
    <div className="VideoContainer">
       <ReactPlayer 
       controls

       className="MasterVideo"
       url="https://www.facebook.com/112245710635558/videos/541813967211548"
       />
      
    </div>
  
    <VideoHome />
  
    <VideoAll />
    <div className="bottomLinkFlex">
            <a href="https://www.facebook.com/MBTellsitlikeitis/" target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" className="introlink"/></a>
            <a href="https://www.twitch.tv/tellitlikeitismb" target="_blank" rel="noreferrer"><img src={Twitchlink} alt="youtube" className="introlink2"/></a>
          </div>
    </div>

  );
}
}

export default VidF;
