import './App.css';
import React, { Component } from "react";
import LogoOne from "./images/MBlogo.svg"
import Header from "./components/header/Header";
import SideDrawer from "./components/sideDrawer/sideDrawer";
import Backdrop from "./components/backdrop/backdrop";
import Facebook from "./images/facebook.svg";
import Twitchlink from "./images/twitch.svg";
import Sig from "./images/sig.svg"
import { HashLink } from "react-router-hash-link";
import VideoHome from "./components/Videolist/Videohome";


class App extends Component {

  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  };  
  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer />
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
    <div className="App">
      <Header drawerClickHandler={this.drawerToggleClickHandler} />
    
       {sideDrawer}
       {backdrop}
       
      <div className="introImg">
        <p className="introTxt1">The task of the modern educator is not to tear down jungles, but to irrigate deserts.</p>
        <img src={Sig} alt="-C.S. Lewis" className="signature" />
      </div>
      <div className="marlon">
        <div className="MBLogoWrap">
        <img src={LogoOne} alt="MB"  className="MBLogo"/>
        <div className="linkBox1">
        
        </div>
        </div>
      <HashLink smooth to="/Live#" className="liveLinkMain" >
        <div className="mainlivebutton">
        <h3 className="mainlivebuttontext"><span className="livealt">Live</span>Stream</h3>
        </div>
      </HashLink>
      </div>
      <div className="divider">
      <div className="introLinkFlex">
      <a href="https://www.facebook.com/MBTellsitlikeitis/" target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" className="introlink"/></a>
            <a href="https://www.twitch.tv/tellitlikeitismb" target="_blank" rel="noreferrer"><img src={Twitchlink} alt="youtube" className="introlink2"/></a>
          </div>
      </div>
      <div className="mainBottomWrap">
        <div className="homeBottomHead">
          <p className="homeBottomTitle">Recent Videos</p>
        </div>
     <VideoHome />
     <div className="HomeBottom">
       <p className="homeBottomText">Scroll |<span className="HBalt1"> |</span> <span className="HBalt2">|</span></p>
     </div>
      </div>
    </div>
  );
}
}

export default App;
