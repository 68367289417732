import React from 'react';
import "./toggleButton.css";

const drawerToggleButton = props => (
    <button className="toggleButton" onClick={props.click}>
        <div className="toggle_line"/>
        <div className="toggle_line_mid"/>
        <div className="toggle_line"/>
    </button>
);

export default drawerToggleButton;