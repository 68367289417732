import React from "react";
import "./Videolist.css";
import { HashLink } from "react-router-hash-link";
import Vidseven from "../../images/VidSeven.png";
import Vidtwo from "../../images/VidTwo.png";
import Vidthree from "../../images/VidThree.png";
import Vidfour from "../../images/VidFour.png";
import Vidfive from "../../images/VidFive.png";
import Vidsix from "../../images/VidSix.png";
import ScrollContainer from "react-indiana-drag-scroll";




const VideoAll = props => (
  <div className="VidContainer">
    <ScrollContainer className="homeScrollLive">
    <HashLink className="RecentPageLink" smooth to="#">
      recent Videos
    </HashLink>
    <HashLink className="HomeVidLinks" smooth to="/VidG#">
          <img src={Vidseven} alt="vid7" className="HomeVid"/>
          <div className="videoBanner">
            <p className="bannerTxt">I'm back and ready</p><span className="timeGap">1:07:38</span>
            </div>
          </HashLink>
    <HashLink className="HomeVidLinks" smooth to="/VidF#">
          <img src={Vidsix} alt="vid1" className="HomeVid"/>
          <div className="videoBanner">
            <p className="bannerTxt">Oakley School Board Fiasco</p><span className="timeGap">36:13</span>
            </div>
          </HashLink>
        <HashLink className="HomeVidLinks" smooth to="/VidE#">
          <img src={Vidfive} alt="vid2" className="HomeVid" />
          <div className="videoBanner">
            <p className="bannerTxt">American Conservatism...where do we go now?</p><span className="timeGap">2:15:01</span>
            </div>
        </HashLink>
        <HashLink className="HomeVidLinks" smooth to="/VidD#">
        <img src={Vidfour} alt="vid3" className="HomeVid"/>
        <div className="videoBanner">
            <p className="bannerTxt">New Website Launch Day</p><span className="timeGap">2:10:04</span>
            </div>
          </HashLink>
          <HashLink className="HomeVidLinks" smooth to="/VidC#">
          <img src={Vidthree} alt="vid4" className="HomeVid"/>
          <div className="videoBanner">
            <p className="bannerTxt">Life and Curve balls</p><span className="timeGap">1:14:24</span>
            </div>
          </HashLink>
          <HashLink className="HomeVidLinks" smooth to="/VidB#">
          <img src={Vidtwo} alt="vid5" className="HomeVid"/>
          <div className="videoBanner">
            <p className="bannerTxt">Unity...behind what?</p><span className="timeGap">1:14:24</span>
            </div>
          </HashLink>
     
     
      </ScrollContainer>
  
  </div>
);

export default VideoAll;