import React from 'react'
import "./contact.css"
import TiliContact from "./images/done.png";
import Twitchlink from "./images/twitch.svg";
import Facebook from "./images/facebook.svg";



const ContactForm = () => {

  

  return (
    <>
      <div className="FormTextWrap">
        <h3 className="FormTextT">Contact info</h3>
        <p className="FormText">You can contact</p>
      <img src={TiliContact} alt="Tell It Like It Is" className="contactImage1" />
       <p className="FormText">Via: <span className="FormTextE">tellitlikeitismb@outlook.com</span></p>
    
       </div>
      <form>
      <p className="FormText">or leave your information below</p>
        <div className="inputWrap">
        <input
          type="text"
          name="name"
          placeholder="Name"
          className="FormInput"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          className="FormInput"
        />
        </div>
        <div className="bottomFormWrap">
        <textarea
          type="text"
          name="message"
          placeholder="Message"
          className="MessageInput"
        ></textarea>
        <button className="Contactbutton" type="submit">Submit</button>
      </div>
      </form>
      <div className="bottomLinkFlex">
            <a href="https://www.facebook.com/MBTellsitlikeitis/" target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" className="introlink"/></a>
            <a href="https://www.twitch.tv/tellitlikeitismb" target="_blank" rel="noreferrer"><img src={Twitchlink} alt="youtube" className="introlink2"/></a>
          </div>
    </>
  )
  }

export default ContactForm