import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import Live from "./Live"
import Contact from "./contact"
import VidA from "./components/Videos/VidA";
import VidC from "./components/Videos/VidC";
import VidD from "./components/Videos/VidD";
import VidE from "./components/Videos/VidE";
import VidF from "./components/Videos/VidF";
import VidB from "./components/Videos/VidB";
import VidG from "./components/Videos/VidG";


const routing = (
  <Router>
    <div>
      <Route exact path="/" component={App} />
      <Route exact path="/Live" component={Live} />
      <Route exact path="/Contact" component={Contact} />
      <Route exact path="/VidA" component={VidA} />
      <Route exact path="/VidB" component={VidB} />
      <Route exact path="/VidC" component={VidC} />
      <Route exact path="/VidD" component={VidD} />
      <Route exact path="/VidE" component={VidE} />
      <Route exact path="/VidF" component={VidF} />
      <Route exact path="/VidG" component={VidG} />
    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))

