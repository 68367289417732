import './App.css';
import React, { Component } from "react";
import Header from "./components/header/Header";
import SideDrawer from "./components/sideDrawer/sideDrawer";
import Backdrop from "./components/backdrop/backdrop";
import ContactForm from "./Form";
import "./contact.css";


class Contact extends Component {

  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  };  
  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer />
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
     
    <div className="App">
      <Header drawerClickHandler={this.drawerToggleClickHandler} />
    
       {sideDrawer}
       {backdrop}
       <div className="formpage">
           <ContactForm />
       </div>
  
    </div>

  );
}
}

export default Contact;
