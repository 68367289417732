import React from "react";
import './header.css'
import DrawerToggleButton from "../sideDrawer/DrawerToggleButton";
import { HashLink } from "react-router-hash-link";

const Header = props => (
  <div className="heading">
    <HashLink to="/#" className="homeLinkTop">
    <div className="titleWrap">
      <p className="HomeTitle">Tell It Like It Is</p>
      </div>
    </HashLink>
    <div>
      <DrawerToggleButton click={props.drawerClickHandler} />
    </div>
    <div className="headerLinkFlex">

          </div>
  </div>
);

export default Header;
