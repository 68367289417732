import './App.css';
import React, { Component } from "react";
import Header from "./components/header/Header";
import SideDrawer from "./components/sideDrawer/sideDrawer";
import Backdrop from "./components/backdrop/backdrop";
import VideoHome from './components/Videolist/Videohome';
import Iframe from 'react-iframe'
import Facebook from "./images/facebook.svg";
import Twitchlink from "./images/twitch.svg";
import VideoAll from "./components/Videolist/Videoall";


class Live extends Component {

  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  };  
  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer />
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
     
    <div className="App">
      <Header drawerClickHandler={this.drawerToggleClickHandler} />
    
       {sideDrawer}
       {backdrop}
       
    <div className="LiveContainer">
    <Iframe url=" https://player.twitch.tv/?channel=tellitlikeitismb&parent=tellitlikeitismb.com"
            position="absolute"
            width="100%"
            id="myId"
            className="Twitch"
            height="100%"
            styles={{height: "25px"}}
            allowfullscreen 
            />
    </div>
    <div className="videoListWrap">
    <VideoHome />
    <VideoAll />
    </div>
    <div className="bottomLinkFlex">
            <a href="https://www.facebook.com/MBTellsitlikeitis/" target="_blank" rel="noreferrer"><img src={Facebook} alt="facebook" className="introlink"/></a>
            <a href="https://www.twitch.tv/tellitlikeitismb" target="_blank" rel="noreferrer"><img src={Twitchlink} alt="youtube" className="introlink2"/></a>
          </div>
    </div>

  );
}
}

export default Live;
